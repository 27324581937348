<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="false"
    max-width="600px"
    transition="dialog-transition"
  >
    <v-card class="pa-4">
      <div class="text-right d-grid grid-col-1-auto">
        <div class="text-left text-h6 pa-2">
          Configurações da turma
        </div>
        <v-btn text @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="pa-3 text-body-2 rounded-lg  mb-2">
        <div class="text-body-2 d-grid grid-col-1-1">
          <div>
            Nome: <strong>{{ turma.nome }}</strong>
          </div>
          <div>
            Plano: <strong>{{ turma.pacoteTitulo }}</strong>
          </div>
          <div>
            Evento: <strong>{{ turma.dataFormatura }}</strong>
          </div>
          <div>
            Criada por: <strong>{{ turma.usuarioCriouTurma }}</strong>
          </div>
          <div>
            Participantes: <strong>{{ turma.numeroFormando }}</strong>
          </div>
          <div></div>
        </div>
      </div>

      <div
        v-if="respostaNula"
        class="text-body-1 orange lighten-5 grey--text text--darken-2 pa-4 rounded-lg"
      >
        Atualmente essa turma não contém uma quantidade definida pela gestão,
        então a permissão dela é apenas 1 administrador.
      </div>

      <div class="px-2 mt-3 text-left justify-center vertical-center">
        <v-row class="text-center" justify="center" align="center">
          <v-col cols="8" class="font-weight-bold">Editores</v-col>
          <v-col cols="4" class="font-weight-bold">Valor atual</v-col>
        </v-row>
        <v-row justify="center" align="center" class="text-center">
          <v-col cols="8">
            <v-text-field
              v-model="includeConfig.qtdAdmin"
              hide-details
              outlined
              label="Quantidade de administradores"
              type="number"
              min="0"
            />
          </v-col>
          <v-col cols="4">
            <v-sheet
              rounded
              shaped
              color="grey lighten-3"
              class="grey--text text--darken-2 pa-3 mx-auto"
            >
              {{ quantidadeAdmin ? quantidadeAdmin : "S/REGISTRO" }}
            </v-sheet>
          </v-col>
        </v-row>

        <v-row
          justify="start"
          align="center"
          class="text-center"
          style="border-width: 1px; border-style: solid; border-radius: 5px; border-color: #CCCCCC; margin: 10px 2px 10px 2px;"
        >
          <v-col
            cols="12"
            class="font-weight-normal"
            style="color: rgba(0, 0, 0, 0.6);"
          >
            A configuração dos percentuais de juros e multa por turma. Caso não
            seja informada, o sistema utilizará os valores das configurações
            gerais da plataforma.
          </v-col>

          <v-checkbox
            v-model="includeConfig.disableJurosMulta"
            style="margin-left: 10px;"
            :label="
              `Desabilita o juros e multa no caso de atraso nos pagamentos`
            "
          ></v-checkbox>
          <v-col cols="6">
            <v-text-field
              v-model="includeConfig.peJuros"
              hide-details
              outlined
              label="Percentual de Juros"
              type="number"
              min="0"
              :disabled="includeConfig.disableJurosMulta"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="includeConfig.peMulta"
              hide-details
              outlined
              label="Percentual de Multa"
              type="number"
              min="0"
              :disabled="includeConfig.disableJurosMulta"
            />
          </v-col>
        </v-row>
        <div>
          <v-checkbox
            v-model="includeConfig.disableComissao"
            :label="`Dispensar a autorização da comissão de autorização`"
          ></v-checkbox>
          <v-checkbox
            v-model="includeConfig.enableFCZ"
            :label="`Ativar Formatura Custo Zero`"
          ></v-checkbox>
        </div>
      </div>
      <div class="grid grid-cols-2 mt-6 pa-1">
        <div></div>
        <div>
          <v-btn
            :loading="loading"
            color="primary"
            depressed
            @click="salvarConfiguracoes()"
            >salvar</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import serviceTurma from "@/services/turma";
export default {
  props: {
    turma: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      respostaNula: false,
      dialog: true,
      loading: false,
      quantidadeAdmin: 0,
      includeConfig: {
        qtdAdmin: null,
        disableComissao: false,
        enableFCZ: false,
        disableJurosMulta: false,
        peMulta: 0,
        peJuros: 0
      }
    };
  },
  mounted() {
    this.loadConfig();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    loadConfig() {
      serviceTurma
        .getSettings(this.turma.id)
        .then(resp => {
          const response = resp.data;
          if (!response) {
            this.respostaNula = true;
            this.includeConfig.qtdAdmin = null;
            this.quantidadeAdmin = null;
            this.includeConfig.disableComissao = false;
            this.includeConfig.enableFCZ = false;
            this.includeConfig.disableJurosMulta = false;
            this.includeConfig.peMulta = 0;
            this.includeConfig.peJuros = 0;
          } else {
            this.respostaNula = false;
            this.quantidadeAdmin = response.qtdAdmin;
            this.includeConfig.qtdAdmin = response.qtdAdmin;
            this.includeConfig.enableFCZ = response.enableFCZ;
            this.includeConfig.disableComissao = !!response.disableComissao;
            this.includeConfig.disableJurosMulta = !!response.disableJurosMulta;
            this.includeConfig.peMulta = response.peMulta || 0;
            this.includeConfig.peJuros = response.peJuros || 0;
          }
          this.loading = false;
        })
        .catch(err => {
          this.$root.showError(err);
          this.loading = false;
        });
    },

    salvarConfiguracoes() {
      if (!this.includeConfig.qtdAdmin) {
        this.$root.showError(
          "A quantidade de administradores não pode nula ou vazia"
        );
        return false;
      }
      this.loading = true;
      const payload = {
        turmaId: this.turma.id,
        qtdAdmin: this.includeConfig.qtdAdmin,
        disableComissao: this.includeConfig.disableComissao,
        enableFCZ: this.includeConfig.enableFCZ,
        disableJurosMulta: this.includeConfig.disableJurosMulta,
        peMulta: this.includeConfig.peMulta,
        peJuros: this.includeConfig.peJuros
      };
      serviceTurma
        .setSettings(payload)
        .then(resp => {
          this.$root.showSucesso(resp.data);
          this.loadConfig();
        })
        .catch(err => {
          this.$root.showError(err.data);
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
